import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React, { useState } from 'react'
import * as st from '~/assets/styl/BenefitsConsortium.module.styl'
import cn from 'classnames'
import { useCookies } from 'react-cookie'
import InputMask from 'react-input-mask'
import ConsortiumDifferentials from './ConsortiumDifferentials'
import person from '~/assets/img/person.png'
import agend from '~/assets/img/agend.png'
import winner from '~/assets/img/winner.png'
import circle from '~/assets/img/circle.png'
import requestData from '~/api/form'
import { navigate } from 'gatsby'

const BenefitsConsortium = () => {
  const coveragesData = {
    safetyTitle: 'Diferenciais do consórcio',
    safetyContent: [
      {
        name: 'Consultoria Personalizada',
        description:
          'Juntos, definimos o melhor plano para atingir o seu objetivo',
        img: person,
      },
      {
        name: 'Contratação simples e rápida',
        description:
          'Ao fechar com a gente, assine a proposta digital de onde estiver.',
        img: circle,
      },
      {
        name: 'Chances reais desde o 1ª mês',
        description: 'Após o 1 mês, você já participa das assembleias.',
        img: agend,
      },
      {
        name: 'O momento mais esperado',
        description:
          'Ao ser contemplado, seu crédito é liberado e você conquista seu obejtivo',
        img: winner,
      },
    ],
  }

  const location = 'consorcio'
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    whatsapp: '',
    safe: 'none',
  })
  const [terms, setTerms] = useState(false)
  const [cookies] = useCookies()

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const updateForm = { ...formData }
    updateForm[e.target.name] = e.target.value

    setFormData(updateForm)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    requestData('post', 'conversions', {
      nome: formData.name,
      email: formData.email,
      celular: formData.whatsapp.replace(/\D/g, ''),
      seguro: `Seguro Consórcio - ${formData.safe}`,
      terms_of_use: terms,
      token_rdstation: '4670dfd37e991ea03d5ff714ede2d785',
      identificador: 'Simulação de Consórcio',
      c_utmz: cookies?.['__trf.src'] || cookies?.__utmzz,
    }).then((data) => {
      if (data.result === 'success') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'GA - Eventos',
          eventCategory: 'Envio de formulário',
          eventLabel: 'Formulário de contato',
        })
        navigate('/sucesso')
      } else {
        alert(data.msg)
      }
    })
  }
  return (
    <div className={cn(st.core)}>
      <section className={cn(st.sectionContainer, 'container clear-fix')}>
        <div>
          <h2 className="grid-9">
            Vantagens de fazer um consórcio com a Dimas Seguros
          </h2>

          <ul className="grid-25 offset-2">
            <li>
              <h3>Todos os planos são sem juros.</h3>
            </li>
            <li>
              <h3>Parcelas reduzidas até a contemplação.</h3>
            </li>
            <li>
              <h3>Use parte do seu crédito para potencializar o lance.</h3>
            </li>
            <li>
              <h3>Grupos com qualidade e segurança.</h3>
            </li>
          </ul>
        </div>
        <AnchorLink to="#simulacao">Solicite informações</AnchorLink>
      </section>
      <div>
        <ConsortiumDifferentials data={coveragesData} pathname="consorcio" />
      </div>
      <section className={st.formContainer} id="simulacao">
        <div className={cn(st.wrapper, 'container')}>
          <h2 className={st.formTitle}>Solicite uma simulação</h2>
          <form onSubmit={handleSubmit} className={st.form}>
            <label htmlFor="name" className={st.label}>
              <span>Nome</span>
              <input
                type="text"
                id="name"
                placeholder="Insira seu nome completo"
                required
                name="name"
                onChange={handleChange}
                value={formData.name}
              />
            </label>
            <label htmlFor="email" className={st.label}>
              <span>E-mail</span>
              <input
                type="email"
                placeholder="Insira seu melhor e-mail"
                required
                name="email"
                onChange={handleChange}
                id="email"
                value={formData.email}
              />
            </label>
            <label htmlFor="whatsapp" className={st.label}>
              <span>WhatsApp</span>
              <InputMask
                type="text"
                id="whatsapp"
                name="whatsapp"
                placeholder="Insira seu DDD + número de WhatsApp"
                mask="(99) 99999-9999"
                required
                onChange={handleChange}
                value={formData.whatsapp}
              />
            </label>
            <label htmlFor="safe" className={st.label}>
              <span>Seguro</span>
              <select
                name="safe"
                id="safe"
                required
                onChange={handleChange}
                value={formData.safe}
              >
                <option value="none" disabled defaultValue={'Selecione'}>
                  Selecione
                </option>
                <option value="Novo seguro">Novo seguro</option>
                <option value="Renovação">Renovação de seguro</option>
              </select>
            </label>
            <div className={st.formBotton}>
              <div className={st.checkbox}>
                <input
                  type="checkbox"
                  value="terms"
                  name="terms"
                  id="terms"
                  required
                  checked={terms}
                  onChange={(e) => setTerms(e.target.checked)}
                />
                <label htmlFor="terms">
                  Li e concordo com os{' '}
                  <a href="/termos-de-uso/">termos de uso.</a>
                </label>
              </div>
              <button className={st.submitButton}>Enviar</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  )
}

export default BenefitsConsortium
