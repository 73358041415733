// extracted by mini-css-extract-plugin
export var checkbox = "BenefitsConsortium-module--checkbox--YdK0M";
export var core = "BenefitsConsortium-module--core--ytBM7";
export var form = "BenefitsConsortium-module--form--LHAA5";
export var formBotton = "BenefitsConsortium-module--formBotton--c1E82";
export var formContainer = "BenefitsConsortium-module--form-container--JrmtT";
export var formTitle = "BenefitsConsortium-module--form-title--dLsqg";
export var label = "BenefitsConsortium-module--label--ukrE5";
export var paddingNone = "BenefitsConsortium-module--padding-none--rtGlc";
export var sectionContainer = "BenefitsConsortium-module--sectionContainer--5asK7";
export var submitButton = "BenefitsConsortium-module--submitButton--H+zjl";
export var wrapper = "BenefitsConsortium-module--wrapper--tfOow";