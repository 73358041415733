import React from 'react'
import * as st from '~/assets/styl/ConsortiumDifferentials.module.styl'

type Props = {
  data: any
  pathname?: string
}

const ConsortiumDifferentials = ({ data, pathname }: Props) => {
  return (
    <div className={st.core}>
      <div>
        <h2>{data.safetyTitle}</h2>
        <p>{data.safetyResume}</p>
        <ul>
          {data.safetyContent.map(({ name, description, img }, index) => (
            <li key={index}>
              <img src={img} />
              <h3>{name}</h3>
              <p>{description}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ConsortiumDifferentials
