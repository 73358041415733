import React from 'react'
import * as st from '~/assets/styl/Coverages.module.styl'

type Props = {
  data: any
  pathname?: string
  widthConsortium?: boolean
}

const Coverages = ({ data, pathname, widthConsortium }: Props) => {
  return (
    <div className={st.core}>
      <section className="container">
        <h2>{data.safetyTitle}</h2>
        <p>{data.safetyResume}</p>
        <ul className={pathname === 'consorcio' ? st.consorcioLayout : ''}>
          {data.safetyContent.map(({ name, description }, index) => (
            <li key={index}>
              <h3 className={widthConsortium ? st.widthTitle : ''}>{name}</h3>
              <p className={widthConsortium ? st.widthParagraph : ''}>
                {description}
              </p>
            </li>
          ))}
        </ul>
      </section>
    </div>
  )
}

export default Coverages
