import React from 'react'
import * as st from '~/assets/styl/Banner.module.styl'
import cn from 'classnames'
import nl2br from 'react-nl2br'

type Props = {
  title: string
  img: string
  mobile: string
  content?: string
  bigBanner?: boolean
  largeTitle?: boolean
  location?: string
  widthConsortium?: boolean
}

const Banner = ({
  title,
  img,
  mobile,
  content,
  bigBanner,
  largeTitle,
  location,
  widthConsortium,
}: Props) => {
  return (
    <>
      <div
        className={cn(st.core, bigBanner && st.heightCustom, st.desk)}
        style={{ background: `url(${img}) top right no-repeat` }}
      >
        <section className="container">
          <h1 className={largeTitle && st.customWidth}>{nl2br(title)}</h1>
          {location === '/seguros/' ? (
            <p>
              Sua vida mais tranquila,
              <span className="block">do seu jeito</span>
            </p>
          ) : (
            <p className={widthConsortium ? st.widthConsortium : ''}>
              {nl2br(content)}
            </p>
          )}
        </section>
      </div>
      <div
        className={cn(st.core, bigBanner && st.heightCustom, st.mobile)}
        style={{ background: `url(${mobile}) top right no-repeat` }}
      >
        <section className="container">
          <h1 className={largeTitle && st.customWidth}>{nl2br(title)}</h1>
          {location === '/seguros/' ? (
            <p>
              Sua vida mais tranquila,
              <span className="block">do seu jeito</span>
            </p>
          ) : (
            <p>{nl2br(content)}</p>
          )}
        </section>
      </div>
    </>
  )
}

export default Banner
