exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-consorcio-tsx": () => import("./../../../src/pages/_consorcio.tsx" /* webpackChunkName: "component---src-pages-consorcio-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/_index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-seguradora-parceiras-tsx": () => import("./../../../src/pages/seguradora-parceiras.tsx" /* webpackChunkName: "component---src-pages-seguradora-parceiras-tsx" */),
  "component---src-pages-seguro-tsx": () => import("./../../../src/pages/_seguro.tsx" /* webpackChunkName: "component---src-pages-seguro-tsx" */),
  "component---src-pages-seguros-tsx": () => import("./../../../src/pages/_seguros.tsx" /* webpackChunkName: "component---src-pages-seguros-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/_sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */),
  "component---src-pages-sucesso-tsx": () => import("./../../../src/pages/sucesso.tsx" /* webpackChunkName: "component---src-pages-sucesso-tsx" */),
  "component---src-pages-termos-de-uso-tsx": () => import("./../../../src/pages/termos-de-uso.tsx" /* webpackChunkName: "component---src-pages-termos-de-uso-tsx" */)
}

