import React from 'react'
import { Link } from 'gatsby'
import * as st from '~/assets/styl/LinkInsurances.module.styl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

type Props = {
  location: string
  data?: any
  consorcio?: boolean
}

const LinkInsurances = ({ data, location, consorcio }: Props) => {
  return (
    <div className={st.core}>
      <p className={consorcio ? st.consorcio : ''}>
        {location === '/'
          ? 'Garanta uma vida mais tranquila.'
          : data.ensureTitle}
      </p>
      {location === '/' ? (
        <Link to="/seguros/">Conheça nossos seguros</Link>
      ) : (
        <AnchorLink
          to={
            consorcio
              ? '/consorcio/#fale-conosco'
              : `/${location}/#fale-conosco`
          }
        >
          {data.ensureCta}
        </AnchorLink>
      )}
    </div>
  )
}

export default LinkInsurances
